import { axiosInstance } from "~/plugins/axios";

export const getSponsorLogo = async (url: string): Promise<string> => {
  try {
    const body = { url };
    const response = await axiosInstance.post("/api/logo", body, {
      responseType: "arraybuffer",
    });

    // Convert the binary data to a base64-encoded Data URL
    const base64Image = arrayBufferToBase64(response.data);
    const dataUrl = `data:image/webp;base64,${base64Image}`;

    return dataUrl;
  } catch (error) {
    throw new Error(`Failed to fetch sponsor logo: ${error.message}`);
  }
};

// Utility function to convert an ArrayBuffer to a base64 string
function arrayBufferToBase64(buffer: ArrayBuffer): string {
  let binary = "";
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}
