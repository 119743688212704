import { subMonths, subYears } from "date-fns";
import { defineStore } from "pinia";

import {
  getUserSummary,
  getUserDemography,
  getCumulativeUsers,
  getCumulativeMeetings,
  getActiveUsers,
  getNewUsers,
  getUsersWithFirstMeetings,
  getMeetings,
  getUsersWithMeeting,
  getAverageMeetingDurations,
  getCheckupResults,
  getContentConsumption,
  getContentConsumptionsByType,
  getContentPopularity,
  getMeetingCountsPerUser,
} from "~/helpers/api/status";
import { generatePDF } from "~/helpers/pdf/pdfgenerator";
import {
  UserSummary,
  UserDemography,
  Aggregation,
  CheckupResult,
  Popularity,
} from "~/model/status";
import type { InsightsProfile } from "~/model/user";
import { VerticalEnum } from "~/model/vertical";

export type FilterRange = "last3Months" | "last6Months" | "lastYear";
export const useStatusStore = defineStore("status", {
  state: () => ({
    filter: {
      sponsorId: null as string | null,
      range: "last3Months" as FilterRange,
      vertical: VerticalEnum.All as VerticalEnum,
    },
    isLoading: false,
    userSummary: null as UserSummary | null,
    userDemography: null as UserDemography | null,
    cumulativeUsers: null as Aggregation[] | null,
    cumulativeMeetings: null as Aggregation[] | null,
    activeUsers: null as Aggregation[] | null,
    newUsers: null as Aggregation[] | null,
    usersWithFirstMeetings: null as Aggregation[] | null,
    meetings: null as Aggregation[] | null,
    usersWithMeeting: null as Aggregation[] | null,
    meetingCountsPerUser: null as { [key: string]: number } | null,
    averageMeetingDurations: null as Aggregation[] | null,
    checkupResults: null as CheckupResult | null,
    contentConsumption: null as Aggregation[] | null,
    contentConsumptionsByType: null as { [key: string]: number } | null,
    contentPopularity: null as Popularity[] | null,
    isInitialized: false,
    isPdfExporting: false,
    isExportApiLoading: false,
    verticalExport: {
      meetings: {} as { [key: string]: Aggregation[] | null },
      usersWithMeeting: {} as { [key: string]: Aggregation[] | null },
      meetingCountsPerUser: {} as {
        [key: string]: { [key: string]: number } | null;
      },
    },
  }),
  getters: {
    getIsInitialized: (state) => {
      return state.isInitialized;
    },
  },
  actions: {
    initializeFilter(profile: InsightsProfile) {
      const localStorageFilter = LocalStorageUtil.getItem("statusFilter");

      if (
        localStorageFilter &&
        profile.sponsors.some((s) => s.id === localStorageFilter.sponsorId)
      ) {
        this.filter = localStorageFilter;
      } else if (profile && this.filter.sponsorId === null) {
        this.filter.sponsorId = profile.sponsors[0].id;
      }

      this.persistFilter();
    },
    updateFilterSponsorId(id: string) {
      this.filter.sponsorId = id;
      this.persistFilter();
    },
    updateFilterRange(range: FilterRange) {
      this.filter.range = range;
      this.persistFilter();
    },
    updateFilterVertical(verticalEnum: VerticalEnum) {
      this.filter.vertical = verticalEnum;
      this.persistFilter();
    },
    persistFilter() {
      LocalStorageUtil.setItem("statusFilter", this.filter);
    },
    async exportToPdf(sponsorLogoUrl: string, sponsorName: string) {
      this.isPdfExporting = true;
      try {
        this.isExportApiLoading = true;
        const promises = verticalOptions.map((vertical) => {
          return Promise.all([
            this.loadMeetings(
              this.filter.sponsorId!,
              this.filter.range,
              vertical,
              true
            ),
            this.loadUsersWithMeeting(
              this.filter.sponsorId!,
              this.filter.range,
              vertical,
              true
            ),
            this.loadMeetingCountsPerUser(
              this.filter.sponsorId!,
              this.filter.range,
              vertical,
              true
            ),
          ]);
        });

        await Promise.all(promises);

        this.isExportApiLoading = false;
        await generatePDF(
          convertRangeToStartDate(this.filter.range),
          sponsorLogoUrl,
          sponsorName
        );
      } catch (error) {
        console.error("Error exporting to PDF:", error);
      } finally {
        this.isPdfExporting = false;
        this.isExportApiLoading = false;
      }
    },
    async loadUserSummary(sponsorId: string) {
      await getUserSummary(sponsorId).then((response) => {
        this.userSummary = response;
      });
    },
    async loadUserDemography(sponsorId: string) {
      await getUserDemography(sponsorId).then((response) => {
        this.userDemography = response;
      });
    },
    async loadCumulativeUsers(sponsorId: string, range: FilterRange) {
      const startDate = convertRangeToStartDate(range);
      await getCumulativeUsers(sponsorId, startDate, "").then((response) => {
        this.cumulativeUsers = response;
      });
    },
    async loadCumulativeMeetings(sponsorId: string, range: FilterRange) {
      const startDate = convertRangeToStartDate(range);
      await getCumulativeMeetings(sponsorId, startDate, "").then((response) => {
        this.cumulativeMeetings = response;
      });
    },
    async loadActiveUsers(sponsorId: string, range: FilterRange) {
      const startDate = convertRangeToStartDate(range);

      await getActiveUsers(sponsorId, startDate).then((response) => {
        this.activeUsers = response;
      });
    },
    async loadNewUsers(sponsorId: string, range: FilterRange) {
      const startDate = convertRangeToStartDate(range);

      await getNewUsers(sponsorId, startDate).then((response) => {
        this.newUsers = response;
      });
    },
    async loadUsersWithFirstMeetings(sponsorId: string, range: FilterRange) {
      const startDate = convertRangeToStartDate(range);
      await getUsersWithFirstMeetings(sponsorId, startDate).then((response) => {
        this.usersWithFirstMeetings = response;
      });
    },
    async loadMeetings(
      sponsorId: string,
      range: FilterRange,
      vertical: string,
      isExport: boolean = false
    ) {
      const startDate = convertRangeToStartDate(range);
      const response = await getMeetings(sponsorId, startDate, vertical);

      if (isExport) {
        this.verticalExport.meetings[vertical] = response;
      } else {
        this.meetings = response;
      }

      return response;
    },
    async loadUsersWithMeeting(
      sponsorId: string,
      range: FilterRange,
      vertical: string,
      isExport: boolean = false
    ) {
      const startDate = convertRangeToStartDate(range);
      const response = await getUsersWithMeeting(
        sponsorId,
        startDate,
        vertical
      );

      if (isExport) {
        this.verticalExport.usersWithMeeting[vertical] = response;
      } else {
        this.usersWithMeeting = response;
      }

      return response;
    },
    async loadMeetingCountsPerUser(
      sponsorId: string,
      range: FilterRange,
      vertical: string,
      isExport: boolean = false
    ) {
      const startDate = convertRangeToStartDate(range);
      const response = await getMeetingCountsPerUser(
        sponsorId,
        startDate,
        vertical
      );

      if (isExport) {
        this.verticalExport.meetingCountsPerUser[vertical] = response;
      } else {
        this.meetingCountsPerUser = response;
      }

      return response;
    },
    async loadAverageMeetingDurations(
      sponsorId: string,
      range: FilterRange,
      vertical?: string
    ) {
      const startDate = convertRangeToStartDate(range);
      await getAverageMeetingDurations(sponsorId, startDate, vertical).then(
        (response) => {
          this.averageMeetingDurations = response;
        }
      );
    },
    async loadCheckupResults(sponsorId: string, range: FilterRange) {
      const startDate = convertRangeToStartDate(range);

      await getCheckupResults(sponsorId, startDate).then((response) => {
        this.checkupResults = response;
      });
    },
    async loadContentConsumption(sponsorId: string, range: FilterRange) {
      const startDate = convertRangeToStartDate(range);

      await getContentConsumption(sponsorId, startDate).then((response) => {
        this.contentConsumption = response;
      });
    },
    async loadContentConsumptionsByType(sponsorId: string, range: FilterRange) {
      const startDate = convertRangeToStartDate(range);
      await getContentConsumptionsByType(sponsorId, startDate).then(
        (response) => {
          this.contentConsumptionsByType = response;
        }
      );
    },
    async loadContentPopularity(sponsorId: string, range: FilterRange) {
      const startDate = convertRangeToStartDate(range);
      await getContentPopularity(sponsorId, startDate).then((response) => {
        this.contentPopularity = response;
      });
    },
  },
});

const convertRangeToStartDate = (range: FilterRange) => {
  const date = new Date();
  switch (range) {
    case "last3Months":
      return subMonths(date, 3);
    case "last6Months":
      return subMonths(date, 6);
    case "lastYear":
      return subYears(date, 1);
    default:
      return date;
  }
};

export const verticalOptions: VerticalEnum[] = [
  VerticalEnum.All,
  VerticalEnum.MentalHealth,
  VerticalEnum.Nutrition,
  VerticalEnum.PhysicalHealth,
  VerticalEnum.Other,
];
