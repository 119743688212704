export enum VerticalEnum {
  SalusCore = "SalusCore",
  MentalHealth = "MentalHealth",
  Nutrition = "Nutrition",
  PhysicalHealth = "PhysicalHealth",
  PrimaryCare = "PrimaryCare",
  Motherhood = "Motherhood",
  Parenthood = "Parenthood",
  Family = "Family",
  Career = "Career",
  Sport = "Sport",
  All = "All",
  Other = "Other",
}

export const getVerticalDisplayName = (vertical: VerticalEnum) => {
  const verticalDisplayNames: Record<VerticalEnum, string> = {
    SalusCore: "",
    MentalHealth: "Mental Sağlık",
    Nutrition: "Beslenme",
    PhysicalHealth: "Fiziksel Sağlık",
    PrimaryCare: "PrimaryCare",
    Motherhood: "Doğum Danışmanlığı",
    Parenthood: "Ebeveyn Danışmanlığı",
    Family: "Aile Danışmanlığı",
    Career: "Kariyer Danışmanlığı",
    Sport: "Spor",
    All: "Tümü",
    Other: "Diğer",
  };

  return verticalDisplayNames[vertical];
};
