import jsPDF from "jspdf";
import insightsLogo from "@/public/images/insights-logo.png";
import separator from "@/public/images/separator.png";
import { dmSansFont } from "./dmSans";
import { dmSerifDisplayFont } from "./dmSerifDisplay";
import { VerticalEnum } from "~/model/vertical";
import { getSponsorLogo } from "../api/sponsor";
import { format } from "date-fns";
import { toJpeg } from "html-to-image";

// Utility function to convert an image from URL to base64 Data URI
async function loadImageAsBase64(url: string): Promise<string> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = "Anonymous";
    img.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);
      const dataURL = canvas.toDataURL("image/png");
      resolve(dataURL);
    };
    img.onerror = reject;
    img.src = url;
  });
}
export const generatePDF = async (
  date: Date,
  sponsorLogoUrl: string,
  sponsorName: string
) => {
  console.log("Starting PDF generation...");
  const doc = new jsPDF("l", "pt", "a4");

  doc.addFileToVFS("dmSans-normal.ttf", dmSansFont);
  doc.addFont("dmSans-normal.ttf", "dmSans", "normal");

  doc.addFileToVFS("dmSerifDisplay-normal.ttf", dmSerifDisplayFont);
  doc.addFont("dmSerifDisplay-normal.ttf", "dmSerifDisplay", "normal");

  doc.setFillColor("#FFF6E5");
  doc.rect(
    0,
    0,
    doc.internal.pageSize.width,
    doc.internal.pageSize.height,
    "F"
  );

  const insightsLogoData = await loadImageAsBase64(insightsLogo);
  const separatorData = await loadImageAsBase64(separator);

  let sponsorLogoData = null;
  try {
    if (sponsorLogoUrl && sponsorLogoUrl !== "") {
      console.log("Fetching sponsor logo...");
      sponsorLogoData = await getSponsorLogo(sponsorLogoUrl);
    } else {
      console.log("No sponsor logo provided.");
    }

    console.log("Adding insights logo...");
    const insightsLogoX = doc.internal.pageSize.width / 2 - 100;
    const insightsLogoY = 100;
    const insightsLogoWidth = 80;
    const insightsLogoHeight = 40;
    doc.addImage(
      insightsLogoData,
      "PNG",
      insightsLogoX,
      insightsLogoY,
      insightsLogoWidth,
      insightsLogoHeight
    );

    console.log("Adding separator...");
    doc.addImage(
      separatorData,
      "PNG",
      doc.internal.pageSize.width / 2 - 1,
      100,
      1,
      42
    );

    const insightsLogoCenterY = insightsLogoY + insightsLogoHeight / 2;

    const sponsorLogoWidth = 100;
    const sponsorLogoHeight = 100;

    const sponsorLogoX = doc.internal.pageSize.width / 2 + 20;
    const sponsorLogoY = insightsLogoCenterY - sponsorLogoHeight / 2;

    if (sponsorLogoData) {
      console.log("Adding sponsor logo...");
      doc.addImage(
        sponsorLogoData,
        "PNG",
        sponsorLogoX,
        sponsorLogoY,
        sponsorLogoWidth,
        sponsorLogoHeight,
        undefined,
        "FAST"
      );
    } else {
      console.log("Adding second insights logo...");
      doc.addImage(
        insightsLogoData,
        "PNG",
        sponsorLogoX,
        insightsLogoY,
        insightsLogoWidth,
        insightsLogoHeight
      );
    }
  } catch (error) {
    console.error("Error capturing sponsor logo:", error);
  }

  console.log("Adding report title...");
  doc
    .setFontSize(60)
    .setFont("dmSerifDisplay", "normal")
    .setTextColor("#18332F")
    .text("İyi oluş raporu", doc.internal.pageSize.width / 2 - 200, 275.0);

  const now = new Date();
  const formattedStartDate = format(date, "dd.MM.yyyy");
  const formattedEndDate = format(now, "dd.MM.yyyy");
  const dateText = `${formattedStartDate} - ${formattedEndDate}`;

  console.log("Adding date text...");
  doc
    .setFontSize(20)
    .setFont("dmSans", "normal")
    .setTextColor("#18332F")
    .text(dateText, doc.internal.pageSize.width / 2 - 110, 335.0);

  // Page 2
  addNewPage(
    doc,
    "Özet (1/4)",
    insightsLogoData,
    separatorData,
    sponsorLogoData
  );
  try {
    await addElement(
      "companyScopeContainer",
      doc,
      30,
      130,
      250,
      250 / getElementRatio("companyScopeContainer")
    );
    await addElement(
      "heltiaMembersContainer",
      doc,
      300,
      130,
      250,
      250 / getElementRatio("heltiaMembersContainer")
    );
    await addElement(
      "meetingHeldMembersContainer",
      doc,
      570,
      130,
      250,
      250 / getElementRatio("meetingHeldMembersContainer")
    );
  } catch (error) {
    console.log(error);
    console.error("Error capturing elements for page 2:", error);
  }

  // Page 3
  addNewPage(
    doc,
    "Özet (2/4)",
    insightsLogoData,
    separatorData,
    sponsorLogoData
  );

  try {
    await addElement(
      "cumulativeUsersContainer",
      doc,
      30,
      130,
      380,
      400 / getElementRatio("cumulativeUsersContainer")
    );
    await addElement(
      "cumulativeMeetingsContainer",
      doc,
      430,
      130,
      380,
      400 / getElementRatio("cumulativeMeetingsContainer")
    );
  } catch (error) {
    console.error("Error capturing elements for page 3:", error);
  }

  // Page 4
  addNewPage(
    doc,
    "Özet (3/4)",
    insightsLogoData,
    separatorData,
    sponsorLogoData
  );

  try {
    await addElement(
      "newcomingUsersContainer",
      doc,
      30,
      130,
      780,
      780 / getElementRatio("newcomingUsersContainer")
    );
  } catch (error) {
    console.error("Error capturing elements for page 4:", error);
  }

  // Page 5
  addNewPage(
    doc,
    "Özet (4/4)",
    insightsLogoData,
    separatorData,
    sponsorLogoData
  );
  try {
    await addElement(
      "activeUsersContainer",
      doc,
      30,
      130,
      780,
      780 / getElementRatio("activeUsersContainer")
    );
  } catch (error) {
    console.error("Error capturing elements for page 5:", error);
  }

  console.log("Adding vertical pages...");
  await addVerticalPages(
    doc,
    VerticalEnum.All,
    1,
    "Tüm destek alanlarında seanslar",
    insightsLogoData,
    separatorData,
    sponsorLogoData
  );
  await addVerticalPages(
    doc,
    VerticalEnum.MentalHealth,
    3,
    "Mental sağlık alanında seanslar",
    insightsLogoData,
    separatorData,
    sponsorLogoData
  );
  await addVerticalPages(
    doc,
    VerticalEnum.Nutrition,
    5,
    "    Beslenme alanında seanslar    ",
    insightsLogoData,
    separatorData,
    sponsorLogoData
  );
  await addVerticalPages(
    doc,
    VerticalEnum.PhysicalHealth,
    7,
    "Fiziksel sağlık alanında seanslar",
    insightsLogoData,
    separatorData,
    sponsorLogoData
  );
  await addVerticalPages(
    doc,
    VerticalEnum.Other,
    9,
    "Diğer destek alanlarında seanslar",
    insightsLogoData,
    separatorData,
    sponsorLogoData
  );

  // Page 9
  addNewPage(
    doc,
    "Pozitif Etki (1/2)",
    insightsLogoData,
    separatorData,
    sponsorLogoData
  );
  try {
    await addElement(
      "checkupsContainer",
      doc,
      30,
      130,
      780,
      780 / getElementRatio("checkupsContainer")
    );
  } catch (error) {
    console.error("Error capturing elements for page 9:", error);
  }

  // Page 10
  addNewPage(
    doc,
    "Pozitif Etki (2/2)",
    insightsLogoData,
    separatorData,
    sponsorLogoData
  );
  try {
    await addElement(
      "averageMeetingDurationsContainer",
      doc,
      60,
      130,
      280,
      280 / getElementRatio("averageMeetingDurationsContainer")
    );
    // Calculate the x position for the second image with a gap of 40 pixels
    const firstImageWidth = 280;
    const gap = 40;
    const secondImageX = 60 + firstImageWidth + gap;
    await addElement(
      "averageContentConsumptionContainer",
      doc,
      secondImageX,
      130,
      280,
      280 / getElementRatio("averageContentConsumptionContainer")
    );
  } catch (error) {
    console.error("Error capturing elements for page 10:", error);
  }

  addNewPage(
    doc,
    "İçerikler",
    insightsLogoData,
    separatorData,
    sponsorLogoData
  );

  try {
    const elementWidth = 380; // Half of the page width minus margins
    const popularContentsHeight =
      elementWidth / getElementRatio("popularContentsContainer");
    const contentTypesHeight =
      elementWidth / getElementRatio("contentTypesContainer");
    const elementHeight = Math.max(popularContentsHeight, contentTypesHeight);

    await addElement(
      "popularContentsContainer",
      doc,
      30,
      130,
      elementWidth,
      elementHeight
    );
    await addElement(
      "contentTypesContainer",
      doc,
      30,
      130,
      elementWidth,
      elementHeight
    );
  } catch (error) {
    console.error("Error capturing elements for page 11:", error);
  }

  // Page 11
  addNewPage(
    doc,
    "Demografi",
    insightsLogoData,
    separatorData,
    sponsorLogoData
  );
  try {
    await addElement(
      "demographyGenderContainer",
      doc,
      30,
      130,
      250,
      250 / getElementRatio("demographyGenderContainer")
    );
    await addElement(
      "demographyAgeContainer",
      doc,
      300,
      130,
      250,
      250 / getElementRatio("demographyAgeContainer")
    );
    await addElement(
      "demographyParenthoodContainer",
      doc,
      570,
      130,
      250,
      250 / getElementRatio("demographyParenthoodContainer")
    );
  } catch (error) {
    console.error("Error capturing elements for page 12:", error);
  }

  console.log("Adding last page...");
  addNewPage(doc, "", insightsLogoData, separatorData, sponsorLogoData);
  doc
    .setFont("dmSerifDisplay", "normal")
    .setFontSize(72)
    .setTextColor("#18332F")
    .text("Teşekkürler", doc.internal.pageSize.width / 2 - 190, 280.0);

  doc.save(
    `HeltiaInsights_${sponsorName.replace(/\s+/g, "")}_${format(
      now,
      "ddMMyyyy"
    )}.pdf`
  );
  console.log("PDF generation completed.");
};

function addNewPage(
  doc,
  title,
  insightsLogoData,
  separatorData,
  sponsorLogoData
) {
  console.log(`Adding new page: ${title}`);
  doc.addPage();
  doc.setFont("dmSans");
  doc.setFillColor("#FFF6E5");
  doc.rect(
    0,
    0,
    doc.internal.pageSize.width,
    doc.internal.pageSize.height,
    "F"
  );
  doc
    .setFont("dmSerifDisplay", "normal")
    .setFontSize(24)
    .setTextColor("#18332F")
    .text(title, doc.internal.pageSize.width / 2 - 80, 50.0);

  const logoY = 520; // Vertical position for logos
  const insightsLogoWidth = 80;
  const insightsLogoHeight = 40;
  const sponsorLogoWidth = 100;
  const sponsorLogoHeight = 100;

  // Calculate the vertical center of the insights logo
  const insightsLogoCenterY = logoY + insightsLogoHeight / 2;

  // Adjust the vertical position of the sponsor logo to align with the insights logo
  const adjustedSponsorLogoY = insightsLogoCenterY - sponsorLogoHeight / 2;

  // Add insights logo on the left
  doc.addImage(
    insightsLogoData,
    "PNG",
    doc.internal.pageSize.width / 2 - 100,
    logoY,
    insightsLogoWidth,
    insightsLogoHeight
  );

  // Add separator in the middle
  doc.addImage(
    separatorData,
    "PNG",
    doc.internal.pageSize.width / 2 - 1,
    logoY,
    1,
    40
  );

  // Conditionally add sponsor logo or second insights logo on the right
  if (sponsorLogoData) {
    console.log("Adding sponsor logo...");
    doc.addImage(
      sponsorLogoData,
      "PNG",
      doc.internal.pageSize.width / 2 + 10,
      adjustedSponsorLogoY,
      sponsorLogoWidth,
      sponsorLogoHeight
    );
  } else {
    console.log("Adding second insights logo...");
    doc.addImage(
      insightsLogoData,
      "PNG",
      doc.internal.pageSize.width / 2 + 10,
      logoY,
      insightsLogoWidth,
      insightsLogoHeight
    );
  }
}

async function addVerticalPages(
  doc,
  vertical,
  pageIndex,
  subtitle,
  insightsLogoData,
  separatorData,
  sponsorLogoData
) {
  console.log(`Adding vertical pages for ${vertical} (${pageIndex}/10)`);
  addNewPage(
    doc,
    `Seanslar (${pageIndex}/10)`,
    insightsLogoData,
    separatorData,
    sponsorLogoData
  );
  doc
    .setFont("dmSans", "normal")
    .setFontSize(12)
    .setTextColor("#18332F")
    .text(subtitle, doc.internal.pageSize.width / 2 - 90, 80.0);

  try {
    console.log(`Capturing ${vertical} meetings...`);
    await addElement(
      `${vertical}Meetings`,
      doc,
      30,
      130,
      380,
      400 / getElementRatio(`${vertical}Meetings`)
    );

    console.log(`Capturing ${vertical} users...`);
    await addElement(
      `${vertical}Users`,
      doc,
      430,
      130,
      380,
      400 / getElementRatio(`${vertical}Users`)
    );

    addNewPage(
      doc,
      `Seanslar (${pageIndex + 1}/10)`,
      insightsLogoData,
      separatorData,
      sponsorLogoData
    );
    doc
      .setFont("dmSans", "normal")
      .setFontSize(12)
      .setTextColor("#18332F")
      .text(subtitle, doc.internal.pageSize.width / 2 - 90, 80.0);

    console.log(`Capturing ${vertical} meetings per user...`);
    await addElement(
      `${vertical}MeetingsPerUser`,
      doc,
      30,
      130,
      780,
      780 / getElementRatio(`${vertical}MeetingsPerUser`)
    );
  } catch (error) {
    console.error(
      `Error capturing elements for ${vertical} vertical pages:`,
      error
    );
  }
}

function getElementRatio(elementId: string): number {
  const element = document.getElementById(elementId);
  if (!element) {
    throw new Error(`Element with ID ${elementId} not found`);
  }
  return element.offsetWidth / element.offsetHeight;
}

export const addElement = async (
  elementId: string,
  doc,
  x: number,
  y: number,
  width: number,
  height: number
): Promise<boolean> => {
  console.log(`Capturing ${elementId}`);
  await toJpeg(document.getElementById(elementId), {
    skipFonts: true,
    backgroundColor: "#fffdfa",
  }).then(function (dataUrl) {
    doc.addImage(dataUrl, "PNG", x, y, width, height);
  });
  console.log(`Captured ${elementId}`);
  return true;
};
